import axios from 'axios';

interface PrayerTimes {
  fajr: string;
  dhuhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  location: string;
  zone: string;
  state: string;
  date?: string;
}

export const getLocationName = async (lat: number, lng: number): Promise<string> => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`,
      {
        headers: {
          'Accept-Language': 'ms'
        }
      }
    );
    return response.data.display_name.split(',')[0];
  } catch (error) {
    console.error('Error getting location name:', error);
    return 'Lokasi Semasa';
  }
};

const getFallbackTimes = (zone?: string, state?: string, date?: string): PrayerTimes => {
  return {
    fajr: '05:55',
    dhuhr: '13:15',
    asr: '16:35',
    maghrib: '19:20',
    isha: '20:35',
    location: zone || 'Malaysia',
    zone: zone || '',
    state: state || '',
    date: date
  };
};

export const fetchPrayerTimes = async (
  latitude: number, 
  longitude: number, 
  zoneCode?: string,
  date?: string
): Promise<PrayerTimes> => {
  try {
    const code = zoneCode?.split('-')[0] || 'SGR01';
    const formattedDate = date ? new Date(date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];

    try {
      const response = await axios.get(
        `https://www.e-solat.gov.my/index.php?r=esolatApi/takwimsolat&period=today&zone=${code}`,
        {
          headers: {
            'Accept': 'application/json'
          },
          timeout: 5000
        }
      );

      if (response.data?.prayerTime?.[0]) {
        const times = response.data.prayerTime[0];
        return {
          fajr: times.fajr,
          dhuhr: times.dhuhr,
          asr: times.asr,
          maghrib: times.maghrib,
          isha: times.isha,
          location: getZoneName(code),
          zone: getZoneName(code),
          state: getStateFromZoneCode(code),
          date: formattedDate
        };
      }
      throw new Error('Invalid API response format');
    } catch (error) {
      console.warn('JAKIM API failed, using fallback:', error);
      return getFallbackTimes(getZoneName(code), getStateFromZoneCode(code), formattedDate);
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error('API error:', error.message);
    }
    return getFallbackTimes(getZoneName(zoneCode), getStateFromZoneCode(zoneCode || ''), date);
  }
};

const getStateFromZoneCode = (code: string): string => {
  const stateMap: Record<string, string> = {
    'JHR': 'Johor',
    'KDH': 'Kedah',
    'KTN': 'Kelantan',
    'MLK': 'Melaka',
    'NGS': 'Negeri Sembilan',
    'PHG': 'Pahang',
    'PLS': 'Perlis',
    'PNG': 'Pulau Pinang',
    'PRK': 'Perak',
    'SBH': 'Sabah',
    'SGR': 'Selangor',
    'SWK': 'Sarawak',
    'TRG': 'Terengganu',
    'WLY': 'Wilayah Persekutuan'
  };

  const stateCode = code.substring(0, 3);
  return stateMap[stateCode] || 'Malaysia';
};

const getZoneName = (code: string): string => {
  const zoneMap: Record<string, string> = {
    'JHR01': 'Pulau Aur dan Pulau Pemanggil',
    'JHR02': 'Kota Tinggi, Mersing, Johor Bahru',
    'JHR03': 'Kluang, Pontian',
    'JHR04': 'Batu Pahat, Muar, Segamat, Gemas',
    'KDH01': 'Kota Setar, Kubang Pasu, Pokok Sena',
    'KDH02': 'Pendang, Kuala Muda, Yan',
    'KTN01': 'Kota Bharu, Bachok, Pasir Puteh',
    'KTN02': 'Machang, Tanah Merah, Pasir Mas',
    'KTN03': 'Gua Musang, Kuala Krai',
    'MLK01': 'Bandar Melaka, Alor Gajah, Jasin',
    'NGS01': 'Tampin, Gemas',
    'NGS02': 'Seremban, Kuala Pilah, Jelebu',
    'PHG01': 'Pulau Tioman',
    'PHG02': 'Kuantan, Pekan',
    'PHG03': 'Maran, Chenor',
    'PRK01': 'Tapah, Slim River, Tanjung Malim',
    'PRK02': 'Ipoh, Batu Gajah, Kampar',
    'PRK03': 'Pengkalan Hulu, Gerik',
    'PLS01': 'Kangar, Padang Besar',
    'PNG01': 'Georgetown, Butterworth',
    'SBH01': 'Kota Kinabalu, Penampang',
    'SGR01': 'Gombak, Petaling, Sepang',
    'SGR02': 'Sabak Bernam, Kuala Selangor',
    'SGR03': 'Klang, Shah Alam',
    'TRG01': 'Kuala Terengganu, Marang',
    'TRG02': 'Dungun, Kemaman',
    'WLY01': 'Kuala Lumpur',
    'WLY02': 'Putrajaya'
  };

  return zoneMap[code] || code;
};