import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Clock, Bell } from 'lucide-react';

interface PrayerTimes {
  fajr: string;
  dhuhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  location: string;
  zone: string;
  state: string;
  date?: string;
}

interface NextPrayerProps {
  prayerTimes: PrayerTimes;
  darkMode: boolean;
}

interface Prayer {
  name: string;
  time: string;
}

const NextPrayer: React.FC<NextPrayerProps> = ({ prayerTimes, darkMode }) => {
  const [currentPrayer, setCurrentPrayer] = useState<Prayer | null>(null);
  const [nextPrayer, setNextPrayer] = useState<Prayer | null>(null);
  const [timeUntil, setTimeUntil] = useState<string>('');

  useEffect(() => {
    const updatePrayerTimes = () => {
      const now = new Date();
      const currentTimeStr = format(now, 'HH:mm');
      const currentMinutes = timeToMinutes(currentTimeStr);

      const prayers: Prayer[] = [
        { name: 'Subuh', time: prayerTimes.fajr },
        { name: 'Zohor', time: prayerTimes.dhuhr },
        { name: 'Asar', time: prayerTimes.asr },
        { name: 'Maghrib', time: prayerTimes.maghrib },
        { name: 'Isyak', time: prayerTimes.isha }
      ];

      // Find current prayer
      let current = prayers[prayers.length - 1];
      for (let i = 0; i < prayers.length; i++) {
        const prayerMinutes = timeToMinutes(prayers[i].time);
        if (currentMinutes >= prayerMinutes) {
          current = prayers[i];
        }
      }

      // Find next prayer
      let next = prayers[0];
      for (let i = 0; i < prayers.length; i++) {
        const prayerMinutes = timeToMinutes(prayers[i].time);
        if (currentMinutes < prayerMinutes) {
          next = prayers[i];
          break;
        }
      }

      setCurrentPrayer(current);
      setNextPrayer(next);

      // Calculate time until next prayer
      const nextTimeMinutes = timeToMinutes(next.time);
      let diffMinutes = nextTimeMinutes - currentMinutes;
      if (diffMinutes < 0) {
        diffMinutes += 24 * 60;
      }

      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;
      setTimeUntil(`${hours}j ${minutes}m`);
    };

    updatePrayerTimes();
    const interval = setInterval(updatePrayerTimes, 60000);

    return () => clearInterval(interval);
  }, [prayerTimes]);

  const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  if (!currentPrayer || !nextPrayer) return null;

  return (
    <div className="grid grid-cols-2 gap-2 mb-4">
      <div className={`rounded-xl p-3 ${
        darkMode ? 'bg-blue-800/40 border border-blue-400' : 'bg-white'
      } shadow-lg`}>
        <div className="flex flex-col">
          <div className="flex items-center gap-2 mb-1">
            <Clock size={16} className="opacity-75" />
            <p className="text-xs opacity-75">Waktu Sekarang</p>
          </div>
          <p className="text-lg font-bold">{currentPrayer.name}</p>
          <p className="text-xl font-bold">
            {format(new Date(`2024-01-01 ${currentPrayer.time}`), 'h:mm a')}
          </p>
        </div>
      </div>

      <div className={`rounded-xl p-3 ${
        darkMode ? 'bg-gray-800/60' : 'bg-white'
      } shadow-lg`}>
        <div className="flex flex-col">
          <div className="flex items-center gap-2 mb-1">
            <Bell size={16} className="opacity-75" />
            <p className="text-xs opacity-75">Waktu Seterusnya</p>
          </div>
          <p className="text-lg font-bold">{nextPrayer.name}</p>
          <div className={`${darkMode ? 'text-gray-200' : 'text-gray-600'}`}>
            <p className="text-base font-semibold">{timeUntil}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextPrayer;