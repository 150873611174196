import React from 'react';
import { Navigation2, Map, Building2 } from 'lucide-react';

interface NearestMosqueProps {
  darkMode: boolean;
  userLat?: number;
  userLng?: number;
}

const NearestMosque: React.FC<NearestMosqueProps> = ({ darkMode, userLat, userLng }) => {
  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/search/masjid+surau/@${userLat},${userLng},14z`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openWaze = () => {
    const url = `https://www.waze.com/ul?q=masjid&navigate=yes&lat=${userLat}&lon=${userLng}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (!userLat || !userLng) return null;

  return (
    <div className={`rounded-xl p-4 mb-4 ${
      darkMode ? 'bg-gray-800' : 'bg-white'
    } shadow-lg`}>
      <div className="flex items-center gap-2 mb-3">
        <Building2 size={18} />
        <h3 className="text-base sm:text-lg font-semibold">Cari Masjid/Surau Terdekat</h3>
      </div>
      
      <div className="grid grid-cols-2 gap-2">
        <button
          onClick={openGoogleMaps}
          className={`flex items-center justify-center gap-2 py-2.5 px-4 rounded-lg transition-colors ${
            darkMode 
              ? 'bg-gray-700 hover:bg-gray-600' 
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
        >
          <Map size={16} />
          <span className="text-sm">Google Maps</span>
        </button>
        <button
          onClick={openWaze}
          className={`flex items-center justify-center gap-2 py-2.5 px-4 rounded-lg transition-colors ${
            darkMode 
              ? 'bg-gray-700 hover:bg-gray-600' 
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
        >
          <Navigation2 size={16} />
          <span className="text-sm">Waze</span>
        </button>
      </div>
    </div>
  );
};

export default NearestMosque;