import React, { useState, useEffect } from 'react';
import { Download } from 'lucide-react';

interface InstallButtonProps {
  darkMode: boolean;
}

const InstallButton: React.FC<InstallButtonProps> = ({ darkMode }) => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
      // Show tooltip after 2 seconds
      setTimeout(() => setShowTooltip(true), 2000);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = async () => {
    if (!deferredPrompt) return;

    try {
      setShowTooltip(false);
      await deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to install prompt: ${outcome}`);
      setDeferredPrompt(null);
      setIsInstallable(false);
    } catch (error) {
      console.error('Error installing app:', error);
    }
  };

  if (!isInstallable) return null;

  return (
    <div className="relative">
      <button
        onClick={handleInstall}
        className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
          darkMode 
            ? 'bg-blue-600 hover:bg-blue-700 text-white' 
            : 'bg-blue-500 hover:bg-blue-600 text-white'
        }`}
        aria-label="Tambah ke Skrin Utama"
      >
        <Download size={18} />
        <span className="text-sm font-medium">Pasang Aplikasi</span>
      </button>
      {showTooltip && (
        <div className={`absolute right-0 top-full mt-2 p-2 rounded-lg text-xs w-48 ${
          darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
        } shadow-lg z-50 animate-fade`}>
          Pasang aplikasi ini untuk akses mudah dan pantas
          <div className={`absolute -top-1 right-4 w-2 h-2 rotate-45 ${
            darkMode ? 'bg-gray-700' : 'bg-white'
          }`}></div>
        </div>
      )}
    </div>
  );
};

export default InstallButton;