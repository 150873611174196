import React, { useState, useEffect } from 'react';

interface RamadanCountdownProps {
  darkMode: boolean;
}

const HADITHS = [
  '"Senyuman kepada saudaramu adalah sedekah." (HR. Tirmidzi)',
  '"Kebersihan itu sebahagian daripada iman." (HR. Muslim)',
  '"Berlaku baiklah kepada jiran tetangga." (HR. Bukhari)',
  '"Orang yang kuat bukanlah yang menang bergulat, tetapi yang dapat menahan dirinya ketika marah." (HR. Bukhari)',
  '"Tidak beriman seseorang dari kamu sehingga dia mencintai saudaranya seperti dia mencintai dirinya sendiri." (HR. Bukhari)',
  '"Permudahkanlah, jangan mempersulit, dan berilah khabar gembira, jangan membuat orang lari." (HR. Bukhari)',
  '"Barangsiapa yang beriman kepada Allah dan hari akhir, hendaklah dia memuliakan tetangganya." (HR. Bukhari)',
  '"Sebaik-baik manusia adalah yang paling bermanfaat bagi manusia lain." (HR. Ahmad)',
];

const RamadanCountdown: React.FC<RamadanCountdownProps> = ({ darkMode }) => {
  const [currentHadithIndex, setCurrentHadithIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const hadithTimer = setInterval(() => {
      setIsAnimating(false);
      setTimeout(() => {
        setCurrentHadithIndex((prev) => (prev + 1) % HADITHS.length);
        setIsAnimating(true);
      }, 100);
    }, 10000);

    return () => clearInterval(hadithTimer);
  }, []);

  return (
    <div className={`sticky top-0 z-50 w-full ${
      darkMode ? 'bg-gray-800/95' : 'bg-white/95'
    } backdrop-blur-sm shadow-sm transition-colors duration-200`}>
      <div className="container mx-auto px-3 py-2 max-w-lg">
        <div className="overflow-hidden w-full min-h-[1.5rem]">
          <div className={`text-xs text-center ${isAnimating ? 'animate-flip' : 'opacity-0'} ${
            darkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {HADITHS[currentHadithIndex]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RamadanCountdown;