import React from 'react';

interface PrayerCardProps {
  name: string;
  time: string;
  darkMode: boolean;
  icon: string;
  isActive: boolean;
}

const PrayerCard: React.FC<PrayerCardProps> = ({ name, time, darkMode, icon, isActive }) => {
  return (
    <div 
      className={`rounded-lg p-4 ${
        darkMode 
          ? 'bg-gray-800' 
          : 'bg-white'
      } shadow-md transition-all duration-200`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <span className="text-xl sm:text-2xl">{icon}</span>
          <h3 className={`text-base sm:text-lg ${isActive ? 'font-bold' : 'font-medium'}`}>
            {name}
          </h3>
        </div>
        <p className={`text-base sm:text-lg ${isActive ? 'font-bold' : 'font-medium'}`}>
          {time}
        </p>
      </div>
    </div>
  );
};

export default PrayerCard;