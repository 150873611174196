import React from 'react';
import { Moon, Sun } from 'lucide-react';
import { format } from 'date-fns';
import { ms } from 'date-fns/locale';
import InstallButton from './InstallButton';

interface HeaderProps {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  currentDate: Date;
  locationName: string;
}

const Header: React.FC<HeaderProps> = ({ darkMode, setDarkMode, currentDate, locationName }) => {
  return (
    <div className="flex flex-col gap-2 mb-4">
      <div className="flex items-center justify-between">
        <h1 className="text-xl sm:text-2xl font-bold">WaktuSolat.co</h1>
        <div className="flex items-center gap-3">
          <div className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            <span className="font-medium hidden sm:inline">
              {format(currentDate, 'EEEE', { locale: ms })}
            </span>
            <span className="hidden sm:inline mx-1">·</span>
            <span className="font-medium">
              {format(currentDate, 'dd/MM/yyyy')}
            </span>
          </div>
          <InstallButton darkMode={darkMode} />
          <button
            onClick={() => setDarkMode(!darkMode)}
            className={`p-2 rounded-full ${
              darkMode ? 'bg-gray-700 text-yellow-400' : 'bg-gray-200 text-gray-700'
            } hover:scale-110 transition-transform duration-200`}
            aria-label={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            {darkMode ? <Sun size={18} /> : <Moon size={18} />}
          </button>
        </div>
      </div>
      
      <div className="flex items-center justify-between">
        {locationName && (
          <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {locationName}
          </p>
        )}
        <div className={`text-right text-sm sm:hidden ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          <span className="font-medium">
            {format(currentDate, 'EEEE', { locale: ms })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;