import React, { useState, useMemo } from 'react';
import { MapPin, Navigation } from 'lucide-react';

interface LocationSelectorProps {
  darkMode: boolean;
  onLocationSelect: (lat: number, lng: number, name: string) => void;
  currentLocation: string;
  onUseCurrentLocation?: () => void;
}

interface Location {
  name: string;
  lat: number;
  lng: number;
  zone: string;
  state: string;
}

export const MALAYSIAN_ZONES: Location[] = [
  { name: 'JHR01', lat: 2.4853, lng: 103.7618, zone: 'Pulau Aur dan Pulau Pemanggil', state: 'Johor' },
  { name: 'JHR02', lat: 1.4854, lng: 103.7618, zone: 'Kota Tinggi, Mersing, Johor Bahru', state: 'Johor' },
  { name: 'JHR03', lat: 1.8204, lng: 103.3318, zone: 'Kluang, Pontian', state: 'Johor' },
  { name: 'JHR04', lat: 2.0353, lng: 102.5983, zone: 'Batu Pahat, Muar, Segamat, Gemas', state: 'Johor' },
  { name: 'KDH01', lat: 6.1254, lng: 100.3673, zone: 'Kota Setar, Kubang Pasu, Pokok Sena', state: 'Kedah' },
  { name: 'KDH02', lat: 5.6504, lng: 100.4394, zone: 'Pendang, Kuala Muda, Yan', state: 'Kedah' },
  { name: 'KTN01', lat: 6.1254, lng: 102.2386, zone: 'Kota Bharu, Bachok, Pasir Puteh', state: 'Kelantan' },
  { name: 'KTN02', lat: 5.7504, lng: 102.2386, zone: 'Machang, Tanah Merah, Pasir Mas', state: 'Kelantan' },
  { name: 'KTN03', lat: 4.7504, lng: 101.9720, zone: 'Gua Musang, Kuala Krai', state: 'Kelantan' },
  { name: 'MLK01', lat: 2.1896, lng: 102.2501, zone: 'Bandar Melaka, Alor Gajah, Jasin', state: 'Melaka' },
  { name: 'NGS01', lat: 2.7304, lng: 102.2386, zone: 'Tampin, Gemas', state: 'Negeri Sembilan' },
  { name: 'NGS02', lat: 2.7304, lng: 101.9386, zone: 'Seremban, Kuala Pilah, Jelebu', state: 'Negeri Sembilan' },
  { name: 'PHG01', lat: 2.8204, lng: 104.1285, zone: 'Pulau Tioman', state: 'Pahang' },
  { name: 'PHG02', lat: 3.8204, lng: 103.3318, zone: 'Kuantan, Pekan', state: 'Pahang' },
  { name: 'PHG03', lat: 3.8204, lng: 102.8318, zone: 'Maran, Chenor', state: 'Pahang' },
  { name: 'PRK01', lat: 4.0204, lng: 101.3718, zone: 'Tapah, Slim River, Tanjung Malim', state: 'Perak' },
  { name: 'PRK02', lat: 4.5204, lng: 101.0718, zone: 'Ipoh, Batu Gajah, Kampar', state: 'Perak' },
  { name: 'PRK03', lat: 5.5204, lng: 101.0718, zone: 'Pengkalan Hulu, Gerik', state: 'Perak' },
  { name: 'PLS01', lat: 6.4404, lng: 100.1985, zone: 'Kangar, Padang Besar', state: 'Perlis' },
  { name: 'PNG01', lat: 5.4145, lng: 100.3288, zone: 'Georgetown, Butterworth', state: 'Pulau Pinang' },
  { name: 'SBH01', lat: 5.9804, lng: 116.0735, zone: 'Kota Kinabalu, Penampang', state: 'Sabah' },
  { name: 'SGR01', lat: 3.0738, lng: 101.5183, zone: 'Gombak, Petaling, Sepang', state: 'Selangor' },
  { name: 'SGR02', lat: 3.3738, lng: 101.2183, zone: 'Sabak Bernam, Kuala Selangor', state: 'Selangor' },
  { name: 'SGR03', lat: 3.0738, lng: 101.2183, zone: 'Klang, Shah Alam', state: 'Selangor' },
  { name: 'TRG01', lat: 5.3302, lng: 103.1408, zone: 'Kuala Terengganu, Marang', state: 'Terengganu' },
  { name: 'TRG02', lat: 4.7502, lng: 103.4408, zone: 'Dungun, Kemaman', state: 'Terengganu' },
  { name: 'WLY01', lat: 3.1390, lng: 101.6869, zone: 'Kuala Lumpur', state: 'Wilayah Persekutuan' },
  { name: 'WLY02', lat: 2.9260, lng: 101.6960, zone: 'Putrajaya', state: 'Wilayah Persekutuan' }
];

const LocationSelector: React.FC<LocationSelectorProps> = ({
  darkMode,
  onLocationSelect,
  currentLocation,
  onUseCurrentLocation
}) => {
  const [selectedState, setSelectedState] = useState(() => {
    const zone = MALAYSIAN_ZONES.find(z => z.name === currentLocation);
    return zone?.state || 'Kelantan';
  });

  const states = useMemo(() => {
    return Array.from(new Set(MALAYSIAN_ZONES.map(zone => zone.state))).sort();
  }, []);

  const zonesByState = useMemo(() => {
    return MALAYSIAN_ZONES.filter(zone => zone.state === selectedState);
  }, [selectedState]);

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newState = event.target.value;
    setSelectedState(newState);
    const firstZoneInState = MALAYSIAN_ZONES.find(zone => zone.state === newState);
    if (firstZoneInState) {
      onLocationSelect(firstZoneInState.lat, firstZoneInState.lng, firstZoneInState.name);
    }
  };

  const handleZoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedZone = MALAYSIAN_ZONES.find(zone => zone.name === event.target.value);
    if (selectedZone) {
      onLocationSelect(selectedZone.lat, selectedZone.lng, selectedZone.name);
    }
  };

  const selectClassName = `px-3 py-2 rounded-md text-sm appearance-none cursor-pointer transition-colors duration-200 ${
    darkMode 
      ? 'bg-gray-700 hover:bg-gray-600 focus:bg-gray-600 text-white' 
      : 'bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 text-gray-900'
  } focus:outline-none focus:ring-1 ${
    darkMode ? 'focus:ring-blue-500' : 'focus:ring-blue-400'
  }`;

  const selectStyle = {
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='${
      darkMode ? 'white' : 'black'
    }'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
    backgroundPosition: 'right 0.5rem center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.5em 1.5em',
    paddingRight: '2.5rem'
  };

  return (
    <div className={`rounded-lg p-3 ${
      darkMode ? 'bg-gray-800' : 'bg-white'
    } shadow-lg mb-3`}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <select
            value={selectedState}
            onChange={handleStateChange}
            className={`${selectClassName} flex-1`}
            style={selectStyle}
          >
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>

          <select
            value={currentLocation}
            onChange={handleZoneChange}
            className={`${selectClassName} flex-1`}
            style={selectStyle}
          >
            {zonesByState.map((zone) => (
              <option key={zone.name} value={zone.name}>
                {zone.zone}
              </option>
            ))}
          </select>
        </div>

        {onUseCurrentLocation && (
          <button
            onClick={onUseCurrentLocation}
            className={`flex items-center justify-center gap-2 w-full py-2 px-3 rounded-md text-sm transition-colors ${
              darkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-900'
            }`}
          >
            <Navigation size={16} />
            <span>Guna Lokasi Semasa</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default LocationSelector;