import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { fetchPrayerTimes, getLocationName } from './api';
import PrayerCard from './components/PrayerCard';
import NextPrayer from './components/NextPrayer';
import LocationSelector from './components/LocationSelector';
import RamadanCountdown from './components/RamadanCountdown';
import Header from './components/Header';
import NearestMosque from './components/NearestMosque';
import { getCurrentPrayer } from './utils/prayerUtils';
import { MALAYSIAN_ZONES } from './components/LocationSelector';

interface PrayerTimes {
  fajr: string;
  dhuhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  location: string;
  zone: string;
  state: string;
  date?: string;
}

interface UserLocation {
  lat: number;
  lng: number;
  code: string;
}

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode !== null ? JSON.parse(savedMode) : true;
  });
  
  const [prayerTimes, setPrayerTimes] = useState<PrayerTimes | null>(null);
  const [location, setLocation] = useState<string>(() => {
    const savedLocation = localStorage.getItem('selectedLocation');
    return savedLocation || 'KTN01';
  });
  const [locationName, setLocationName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [currentDate] = useState(new Date());
  const [userLocation, setUserLocation] = useState<UserLocation | null>(() => {
    const savedUserLocation = localStorage.getItem('userLocation');
    return savedUserLocation ? JSON.parse(savedUserLocation) : null;
  });
  const [currentPrayerName, setCurrentPrayerName] = useState<string>('');

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('selectedLocation', location);
  }, [location]);

  useEffect(() => {
    if (userLocation) {
      localStorage.setItem('userLocation', JSON.stringify(userLocation));
    }
  }, [userLocation]);

  const findNearestZone = (lat: number, lng: number) => {
    let nearestZone = MALAYSIAN_ZONES[0];
    let minDistance = Number.MAX_VALUE;

    MALAYSIAN_ZONES.forEach(zone => {
      const distance = Math.sqrt(
        Math.pow(zone.lat - lat, 2) + Math.pow(zone.lng - lng, 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        nearestZone = zone;
      }
    });

    return nearestZone;
  };

  const handleLocationSelect = async (lat: number, lng: number, cityName: string) => {
    try {
      setError(null);
      const newUserLocation = { lat, lng, code: cityName };
      setUserLocation(newUserLocation);
      
      const locationDisplayName = await getLocationName(lat, lng);
      setLocationName(locationDisplayName);

      const times = await fetchPrayerTimes(lat, lng, cityName);
      setPrayerTimes(times);
      setLocation(cityName);
    } catch (error) {
      console.error('Error fetching prayer times:', error);
      setError('Failed to fetch prayer times');
    }
  };

  const handleCurrentLocation = async () => {
    try {
      setError(null);
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 5000,
          maximumAge: 0,
          enableHighAccuracy: true
        });
      });

      const nearestZone = findNearestZone(
        position.coords.latitude,
        position.coords.longitude
      );

      await handleLocationSelect(
        position.coords.latitude,
        position.coords.longitude,
        nearestZone.name
      );
    } catch (error) {
      console.error('Location error:', error);
      setError('Location access denied. Please select a zone manually.');
    }
  };

  useEffect(() => {
    if (userLocation) {
      handleLocationSelect(userLocation.lat, userLocation.lng, userLocation.code);
    } else {
      handleLocationSelect(6.1254, 102.2386, 'KTN01');
    }
  }, []);

  useEffect(() => {
    if (prayerTimes) {
      const prayers = [
        { name: 'Subuh', time: prayerTimes.fajr },
        { name: 'Zohor', time: prayerTimes.dhuhr },
        { name: 'Asar', time: prayerTimes.asr },
        { name: 'Maghrib', time: prayerTimes.maghrib },
        { name: 'Isyak', time: prayerTimes.isha }
      ];
      
      const currentTime = format(new Date(), 'HH:mm');
      const current = getCurrentPrayer(prayers, currentTime);
      setCurrentPrayerName(current.name);
    }
  }, [prayerTimes]);

  return (
    <div className={`min-h-screen flex flex-col transition-colors duration-200 ${
      darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'
    }`}>
      <RamadanCountdown darkMode={darkMode} />
      
      <main className="w-full max-w-lg mx-auto px-3 py-4 flex-1 overflow-x-hidden">
        <Header 
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          currentDate={currentDate}
          locationName={locationName}
        />

        <section aria-label="Pilihan Lokasi" className="mb-4">
          <LocationSelector
            darkMode={darkMode}
            onLocationSelect={handleLocationSelect}
            onUseCurrentLocation={handleCurrentLocation}
            currentLocation={location}
          />
          {error && (
            <p className="mt-2 text-sm text-red-500">{error}</p>
          )}
        </section>

        {prayerTimes && (
          <section aria-label="Waktu Solat Seterusnya" className="mb-4">
            <NextPrayer prayerTimes={prayerTimes} darkMode={darkMode} />
          </section>
        )}

        {userLocation && (
          <section aria-label="Cari Masjid Berdekatan" className="mb-4">
            <NearestMosque 
              darkMode={darkMode}
              userLat={userLocation.lat}
              userLng={userLocation.lng}
            />
          </section>
        )}

        <section aria-label="Jadual Waktu Solat" className="grid gap-2 auto-rows-min">
          {prayerTimes && (
            <>
              <PrayerCard 
                name="Subuh" 
                time={format(new Date(`2024-01-01 ${prayerTimes.fajr}`), 'h:mm a')}
                darkMode={darkMode} 
                icon="🌅" 
                isActive={currentPrayerName === 'Subuh'}
              />
              <PrayerCard 
                name="Zohor" 
                time={format(new Date(`2024-01-01 ${prayerTimes.dhuhr}`), 'h:mm a')}
                darkMode={darkMode} 
                icon="☀️" 
                isActive={currentPrayerName === 'Zohor'}
              />
              <PrayerCard 
                name="Asar" 
                time={format(new Date(`2024-01-01 ${prayerTimes.asr}`), 'h:mm a')}
                darkMode={darkMode} 
                icon="🌤️" 
                isActive={currentPrayerName === 'Asar'}
              />
              <PrayerCard 
                name="Maghrib" 
                time={format(new Date(`2024-01-01 ${prayerTimes.maghrib}`), 'h:mm a')}
                darkMode={darkMode} 
                icon="🌅" 
                isActive={currentPrayerName === 'Maghrib'}
              />
              <PrayerCard 
                name="Isyak" 
                time={format(new Date(`2024-01-01 ${prayerTimes.isha}`), 'h:mm a')}
                darkMode={darkMode} 
                icon="🌙" 
                isActive={currentPrayerName === 'Isyak'}
              />
            </>
          )}
        </section>
      </main>
      
      <footer className={`w-full max-w-lg mx-auto text-center py-4 px-3 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        <p>© 2024 WaktuSolat.co - Jadual Waktu Solat Malaysia</p>
        <p className="mt-1">
          Crafted with ❤️ in Kelantan by{' '}
          <a href="https://qimi.co/" target="_blank" rel="noopener noreferrer" className="hover:underline text-blue-500">
            Faqimi
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;