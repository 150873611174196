interface Prayer {
  name: string;
  time: string;
}

export const getCurrentPrayer = (prayers: Prayer[], currentTime: string): Prayer => {
  // Convert current time and prayer times to comparable minutes
  const currentMinutes = timeToMinutes(currentTime);
  
  // Find the last prayer that has already passed
  for (let i = prayers.length - 1; i >= 0; i--) {
    const prayerMinutes = timeToMinutes(prayers[i].time);
    if (currentMinutes >= prayerMinutes) {
      return prayers[i];
    }
  }
  // If no prayer has passed today, return last prayer from previous day
  return prayers[prayers.length - 1];
};

export const getNextPrayer = (prayers: Prayer[], currentTime: string): Prayer => {
  const currentMinutes = timeToMinutes(currentTime);
  
  // Find the next prayer that hasn't occurred yet
  for (let i = 0; i < prayers.length; i++) {
    const prayerMinutes = timeToMinutes(prayers[i].time);
    if (currentMinutes < prayerMinutes) {
      return prayers[i];
    }
  }
  // If all prayers have passed, return first prayer of next day
  return prayers[0];
};

// Helper function to convert time string to minutes
const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};